import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getConnectList as _getConnectList,
  getFilteredConnect as _getFilteredConnect,
  getConnectDetailsData as _getConnectDetailsData,
  updateConnectDetailsData as _updateConnectDetailsData,
  saveConnectDetailsData as _saveConnectDetailsData,
  getAllDistinctStateMaster as _getAllDistinctStateMaster,
  getAllDistinctStateByCountry as _getAllDistinctStateByCountry,
  getAllDistinctCityByState as _getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity as _getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCity as _getAllDistinctLocalityByCity,
  getCompanyWithSearchableText as _getCompanyWithSearchableText,
  deleteConnect as _deleteConnect,
  validateConnectEmail as _validateConnectEmail,
  validateConnectContactNumber as _validateConnectContactNumber,
} from "../services/ConnectDetailsAPI";
import { get } from "lodash";
import { FormatConnectDetails } from "../../utils/formatSearchData/FormatConnectDetails";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  connectDetailsData: [],
  isConnectInfoEditable: false,
  currentPage: 1,
  pageSize: 10,
  connectDetails: {},
  connectId: null,
  stateMaster: [],
  cityMaster: [],
  localityMaster: [],
  connectsCnt: 0,
  isConnectEmailValid: false,
  isConnectContactNumberValid: false,
};

const ConnectDetailsSlice = createSliceWithThunks({
  name: "connectDetails",
  initialState,
  reducers: (create) => ({
    setConnectInfoEditable: (state, action) => {
      state.isConnectInfoEditable = action?.payload;
    },
    getConnectList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getConnectList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.connectDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.connectDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.connectDetailsData = action?.payload?.data?.data;
          state.connectsCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setConnectInfo: (state, action) => {
        state.connectDetails = {
          ...state.connectDetails,
          [action?.payload?.name]: action?.payload?.value,
        };
    },
    updateConnectInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const connectId = thunkAPI.getState().connectDetails?.connectId;
          let data = await _updateConnectDetailsData(_, connectId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    saveConnectInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _saveConnectDetailsData(_);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.connectId = action?.payload?.data?.connect?._id;
        },
      }
    ),
    getFilteredConnect: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getFilteredConnect(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.connectDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.connectDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.connectDetailsData = action?.payload?.data?.data;
          state.connectsCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },
    getConnectDetailsData: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getConnectDetailsData(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.connectDetails = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.connectDetails = {};
        },
        fulfilled: (state, action) => {
          state.connectDetails = FormatConnectDetails(action?.payload?.data);
          state.loading = false;
        },
      }
    ),
    setConnectId: (state, action) => {
      state.connectId = action?.payload;
    },
    setConnectInitialState: (state, action) => {
      state.loading = false;
      state.connectDetailsData = [];
      state.isConnectInfoEditable = false;
      state.currentPage = 1;
      state.pageSize = 10;
      state.connectDetails = {};
      state.connectId = null;
      state.stateMaster = [];
      state.cityMaster = [];
      state.localityMaster = [];
      state.companies = [];
    },
    setConnectsCnt: (state, action) => {
      state.connectsCnt = action?.payload;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    getAllDistinctStateMaster: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDistinctStateMaster();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.stateMaster = [];
        },
        rejected: (state, action) => {
          state.stateMaster = [];
        },
        fulfilled: (state, action) => {
          const stateMasterArray = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            stateMasterArray.push(element);
          }
          state.stateMaster = stateMasterArray;
        },
      }
    ),
    getAllDistinctCityByState: create.asyncThunk(
      async (state, thunkAPI) => {
        try {
          let data = await _getAllDistinctCityByState(state);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.cityMaster = [];
        },
        rejected: (state, action) => {
          state.cityMaster = [];
        },
        fulfilled: (state, action) => {
          const cityMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            cityMasterArray.push(element);
          }
          state.cityMaster = cityMasterArray;
        },
      }
    ),
    getAllDistinctLocalityByCity: create.asyncThunk(
      async (state, thunkAPI) => {
        try {
          let data = await _getAllDistinctLocalityByCity(state);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.localityMaster = [];
        },
        rejected: (state, action) => {
          state.localityMaster = [];
        },
        fulfilled: (state, action) => {
          const localityMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            localityMasterArray.push(element);
          }
          state.localityMaster = localityMasterArray;
        },
      }
    ),
    getCompanyWithSearchableText: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getCompanyWithSearchableText(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.companies = [];
        },
        rejected: (state, action) => {
          state.companies = [];
        },
        fulfilled: (state, action) => {
          const companiesArray = [];
          for (
            let index = 0;
            index < action?.payload?.data?.companies?.length;
            index++
          ) {
            const element = {
              label: action?.payload?.data?.companies?.[index]?.company,
              key: action?.payload?.data?.companies?.[index]?.company,
              value: action?.payload?.data?.companies?.[index]?.company,
              cin: action?.payload?.data?.companies?.[index]?.cin,
            };
            companiesArray.push(element);
          }
          state.companies = companiesArray;
        },
      }
    ),
    deleteConnect: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _deleteConnect(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    validateConnectEmail: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _validateConnectEmail(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.isConnectEmailValid = false;
        },
        rejected: (state, action) => {
          state.isConnectEmailValid = false;
        },
        fulfilled: (state, action) => {
          state.isConnectEmailValid = action?.payload?.data?.result;
        },
      }
    ),
    validateConnectContactNumber: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _validateConnectContactNumber(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.isConnectContactNumberValid = false;
        },
        rejected: (state, action) => {
          state.isConnectContactNumberValid = false;
        },
        fulfilled: (state, action) => {
          state.isConnectContactNumberValid = action?.payload?.data?.result;
        },
      }
    ),
  }),
});

export const {
  getConnectList,
  setConnectInfoEditable,
  setConnectInfo,
  updateConnectInfo,
  getFilteredConnect,
  setCurrentPage,
  setPageSize,
  getConnectDetailsData,
  setConnectId,
  setConnectInitialState,
  saveConnectInfo,
  deleteConnect,
  setLoading,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctLocalityByCity,
  getCompanyWithSearchableText,
  validateConnectEmail,
  validateConnectContactNumber,
} = ConnectDetailsSlice.actions;
export default ConnectDetailsSlice.reducer;
